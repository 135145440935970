import { Button } from "@mui/material";
import { Icons } from "../../misc/Icons";
import Ajax from "../../Ajax";
import React from "react";
import { context } from "../..";
import { observer } from "mobx-react-lite";

export default observer(function Uploads () {
    return (
        <div className="upload-list">
            {context.uploads.map((upload, i) => <Upload key={i} upload={upload} />)}
        </div>
    )
})

export function Upload ({upload}) {

    const [submitting, setSubmitting] = React.useState(false)
    const [data, setData] = React.useState(upload.data)

    const scrollToAnwer = () => {
        var xpath = `//*[@data-value='${upload.displayName}']`;
        var matchingElement = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;

        if (matchingElement) {
            (matchingElement as HTMLElement).scrollIntoView()
            matchingElement.parentElement.style.color = "black"
            matchingElement.parentElement.style.backgroundColor = "#ffdf0096"
            setTimeout(() => {
                matchingElement.parentElement.style.color = "#5b5959"
                matchingElement.parentElement.style.backgroundColor = "transparent"
            }, 2000)
        } else {
            console.log("No match")
        }
    }

    const deleteUpload = async () => {
        setSubmitting(true)
        await Ajax.Upload.Delete(upload.uid).then(() => {
            context.loadQuestionnaire()
        }).catch((response) => {
            console.log(response)
        })
        setSubmitting(false)
    }

    const getUpload = async () => {
        setSubmitting(true)
        await Ajax.Upload.Get(upload.uid).then((response) => {
            setData(response.data.data)
        }).catch((response) => {
            console.log(response)
        })
        setSubmitting(false)
    }

    const formatSrcString = () => {

        var uploadType = upload.fileFormat.toLowerCase()
        var fileFormats = context.getListSet("file_formats")
        
        if (Object.keys(fileFormats).includes(uploadType)) {
            var mimeType = fileFormats[uploadType]
            return `data:${mimeType};base64,${data}`
        }
        return "Nothing"
    }

    var srcString = formatSrcString()

    return (
        <>
            <div className={submitting ? "upload-list-item disabled" : "upload-list-item"}>
                <Icons.Photo />
                
                <small onClick={() => scrollToAnwer()}>
                    {upload.displayName}
                </small>
                
                <div style={{display: "flex", gap: "10px", marginLeft: "auto"}}>
                    {!Object.values(context.answers).includes(upload.uid) ? (
                        <Button className="btn btn-sm" color="error" onClick={() => deleteUpload()}>Delete</Button>
                    ) : null}
                    {data ? (
                        <Button className="btn btn-sm" color="info" variant="contained" onClick={() => setData(null)}>Hide</Button>
                    ) : (
                        <Button className="btn btn-sm" color="info" variant="contained" onClick={() => getUpload()}>View</Button>
                    )}
                </div>
            </div>

            {data && ["PNG", "JPG", "JPEG"].includes(upload.fileFormat) ? (
                <img src={srcString} alt={upload.uid} style={{maxHeight: "300px", maxWidth: "300px", marginLeft: "30px"}} />
            ) : null}

            {data && ["PDF"].includes(upload.fileFormat) ? (
                <iframe src={srcString} style={{maxHeight: "500px", marginLeft: "30px"}} />
            ) : null}
        </>
    )
}