import Page from './Page.tsx'
import { Button, CircularProgress } from "@mui/material"
import { context } from "../../index.js"
import { observer } from "mobx-react-lite"
import PageFinal from "./PageFinal.tsx"
import PreviousAnswers from './PreviousAnswers.tsx'


export default observer(function Questionnaire () {
    return (
        <div className={context.submitting ? "questionnaire disabled" : "questionnaire"}>
            <QuestionnaireHeader />
            <QuestionnaireBody />
        </div>
    )
})


const QuestionnaireHeader = observer(() => {
    
    var submittedPages = context.getSubmittedPages()
        
    return (
        <div className="questionnaire-header">
            <Button
                disabled={context.pageIndex === 0 || submittedPages?.length < 1 || context.showPreviousAnswers}
                className="btn btn-sm"
                variant="contained"
                onClick={() => context.backPage()}>
                <h4>Go back</h4>
            </Button>

            <Button
                disabled={!submittedPages.length}
                className="btn btn-sm"
                variant="contained"
                color={context.showPreviousAnswers ? "success" : "info"}
                onClick={() => context.togglePreviousAnswers()}>
                {context.showPreviousAnswers ? "Back to questionnaire" : "See previous answers"}
            </Button>

            {context.embedded ? (
                <Button
                    className="btn btn-sm"
                    variant="contained"
                    color="info"
                    onClick={() => context.setToken(null)}>
                    Log out
                </Button>
            ) : null}
            
            {/* <small>{context.template.displayName}</small> */}
        </div>
    )
})


const QuestionnaireBody = observer(() => {

    const { submitting, pageIndex, getSubmittedPages, getUnsubmittedPages, showPreviousAnswers } = context

    var unsubmittedPages = getUnsubmittedPages()
    var submittedPages = getSubmittedPages()

    if (submitting) return <CircularProgress style={{width: "18px", height: "18px", margin: "50px 100px"}} />

    if (showPreviousAnswers) {
        return (
            <div>
                <h2 style={{margin: "30px 0 20px 0"}}>Your Answers</h2>
                <p>These are the answers provided by <b>{context.respondentEmail}</b> in response to this '{context.template.displayName}' questionnaire.</p>
                <PreviousAnswers />
            </div>
        )
    }

    if (pageIndex !== null) return <Page page={submittedPages[pageIndex]} />

    if (unsubmittedPages.length) return <Page page={unsubmittedPages[0]} />

    return <PageFinal />
})