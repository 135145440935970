import React from "react";
import { Button } from "@mui/material";
import { IField, IPage } from "../../interfaces";
import { context } from "../..";

export default function PreviousAnswers () {

    const submittedPages = context.getSubmittedPages()

    const goToPage = (pageIndex) => {
        if (context.showPreviousAnswers) {
            context.togglePreviousAnswers()
        }
        context.setPageIndex(pageIndex)
        window.scrollTo(0, 0);
    }

    return (
        <div className="previous-answers">
            {submittedPages?.map((page: IPage, i) => {
                return (
                    <div key={i}>
                        <Button className="btn btn-lg btn-no-padding link" onClick={() => goToPage(i)}>
                            <h4>{context.formatLabel(page.title)}</h4>
                        </Button>

                        <div style={{padding: "0 10px"}}>
                            {page.fields?.map((field: IField, i) => (
                                <QuestionnaireField key={i} field={field} answers={context.answers} />
                            ))}

                            {page.table ? context.answers[page.table?.list_name]?.map((tableEntryAnswers, i) => (
                                <div key={i} style={{margin: "2px 0"}}>
                                    {page.table.fields?.filter(field => !context.isHidden(field, tableEntryAnswers)).map((field, i) => (
                                        <QuestionnaireField key={i} field={field} answers={tableEntryAnswers} />
                                    ))}
                                </div>
                            )) : null}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

function QuestionnaireField ({field, answers}) {
                                
    if (context.isHidden(field)) {
        return <React.Fragment key={field.name}></React.Fragment>
    }

    var answerDisplay = context.formatLabel(answers[field.name])

    if (field.setListKey === "countries") {
        var countriesDict = context.getListSet("countries")
        answerDisplay = countriesDict[answerDisplay]
    } else if (field.options) {
        answerDisplay = field.options[answerDisplay]
    } else if (field.type === "checkbox") {
        answerDisplay = answerDisplay === "true" ? "Yes" : "No"
    } else if (field.type === "file") {
        var upload = context.uploads.find(u => u.uid === answerDisplay)
        if (upload) {
            answerDisplay = upload.displayName
        }
    }

    return (
        <div style={{display: "grid", gridTemplateColumns: "49% 2% 49%"}}>
            <small>{context.formatLabel(field.label)}</small>
            <span></span>
            <small data-value={answerDisplay}>{context.formatLabel(answerDisplay)}</small>
        </div>
    )
}