import { observer } from "mobx-react-lite";
import { context } from "..";
import Logo from "../misc/Logo";
import { Button } from "@mui/material";


export default observer(function Header () {

    // Don't show header if questionnaire is being loaded by an iframe
    if (context.embedded) return <></>

    return (
        <header>
            <div className="main-inner">
                {context.advisorAccount.logoUri 
                    ? <img src={context.advisorAccount.logoUri} alt="logo" height="40" />
                    : <Logo width={100} type="block" />}

                <div style={{display: "flex", gap: "10px"}}>
                    {context.token ? (
                        <>
                            <Button 
                                className="btn btn-sm" 
                                onClick={() => context.setToken(null)}>
                                Log out
                            </Button>
                        </>
                    ) : null}
                </div>
            </div>
        </header>
    )
})